import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './SubNav.css' 

class SubNav extends React.Component {
  static propTypes = {
    // cognito
    user: PropTypes.object.isRequired,

    //userBalanceReducer
    overage: PropTypes.number.isRequired,

    //userBillingCycleReducer
    billingCycle: PropTypes.object,
  }

  render = () => {
    let itemName = ''
    if (this.props.billingCycle) {
      itemName = this.props.billingCycle.name
    }
    return (
      <div className="SubNav">
        <div>{itemName}</div>
        <div className="right">Current Balance ${this.props.overage.toFixed(2)}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user,
  
  billingCycle: state.userBillingCycleReducer.billingCycle,

  overage: state.userBalanceReducer.overage,
  isUserBalanceLoading: state.userBalanceReducer.isUserBalanceLoading,
  userBalanceError: state.userBalanceReducer.error,
})


export default connect(mapStateToProps)(SubNav)
