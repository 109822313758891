import React from 'react'
import { Link } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

const AdminNav = () => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      Admin
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem>
        <NavLink tag={Link} to="/admin/billing_cycles">Billing Cycles</NavLink>
      </DropdownItem>
      <DropdownItem>
        <NavLink tag={Link} to="/admin/user_admin">User Admin</NavLink>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
)

export default AdminNav