import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

class Error extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    color: PropTypes.string,
  }
  render = () => {
    if (!this.props.error) { return '' }
    var color = this.props.color ? this.props.color : 'warning'
    return <Alert color={color}>{this.props.error}</Alert>
  }
}

export default Error