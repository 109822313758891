import { apiUrl } from '../lib/api'

export const firingTempChangeAction = (fireTemp) => dispatch => {
  dispatch({
    type: 'FIRING_TEMP_CHANGE_ACTION',
    fireTemp: fireTemp
  })
}

export const firingLengthChangeAction = (event) => dispatch => {
  dispatch({
    type: 'FIRING_LENGTH_CHANGE_ACTION',
    length: event.target.value
  })
}

export const firingWidthChangeAction = (event) => dispatch => {
  dispatch({
    type: 'FIRING_WIDTH_CHANGE_ACTION',
    width: event.target.value
  })
}

export const firingHeightChangeAction = (event) => dispatch => {
  dispatch({
    type: 'FIRING_HEIGHT_CHANGE_ACTION',
    height: event.target.value
  })
}

export const firingTotalChangeAction = (event) => dispatch => {
  dispatch({
    type: 'FIRING_TOTAL_CHANGE_ACTION',
    length: '',
    width: '',
    height: '',
    total: event.target.value
  })
}

export const firingPiecesChangeAction = (event) => dispatch => {
  dispatch({
    type: 'FIRING_PIECES_CHANGE_ACTION',
    pieces: event.target.value
  })
}

export const firingSubmitAction = (data, token, successCallback) => dispatch => {
  dispatch({
    type: 'FIRING_SUBMIT_ACTION'
  })
  fetch(apiUrl() + '/firelog',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      dispatch(firingSubmitSuccessAction(result))
      if(successCallback) {
        successCallback()
      }
    })
    .catch((error) => {
      dispatch(firingSubmitErrorAction(error))
    })
}

export const firingSubmitSuccessAction = (result) => dispatch => { // eslint-disable-line
  dispatch({
    type: 'FIRING_SUBMIT_SUCCESS_ACTION'
  })
}

export const firingSubmitErrorAction = (error) => dispatch => {
  dispatch({
    type: 'FIRING_SUBMIT_ERROR_ACTION',
    error: error
  })
}

export const firingSubmitValidationErrorAction = () => dispatch => {
  dispatch({
    type: 'FIRING_SUBMIT_VALIDATION_ERROR_ACTION',
    error: 'Form was not completed correctly'
  })
}