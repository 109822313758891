import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import {
  billingCycleListAction
} from '../../actions/billingCycleActions'

import {
  billingCycleImportUserSelect,
  billingCycleImportUserModalSaveAction,
  billingCycleImportUserModalToggleAction,
  billingCycleImportUserQueryAction
} from '../../actions/billingCycleImportUserListModalActions'

import './BillingCycleImportUserListModal.css'

class BillingCycleImportUserListModal extends React.Component {
  static propTypes = {
    // billingCycleActions
    billingCycleListAction: PropTypes.func.isRequired,

    // billingCycleImportUserListModalActions
    billingCycleImportUserSelect: PropTypes.func.isRequired,
    billingCycleImportUserModalSaveAction: PropTypes.func.isRequired,
    billingCycleImportUserModalToggleAction: PropTypes.func.isRequired,
    billingCycleImportUserQueryAction: PropTypes.func.isRequired,

    // cognito
    cognito: PropTypes.object.isRequired,

    // billingCycleReducer
    billingCycleItems: PropTypes.array.isRequired,
    isLoadingBillingCycleList: PropTypes.bool.isRequired,

    // billingCycleImportUserListModal
    error: PropTypes.string,
    importBillingCycleId: PropTypes.string,
    isLoadingUsers: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    selectedUsers: PropTypes.array,
    userResults: PropTypes.array,
  }

  onChangeBillingCycle = (e) => {
    if (!e.target.value) {
      return
    }
    this.props.billingCycleImportUserQueryAction(e.target.value, this.props.cognito)
  }

  onChangeUserSelected = (e) => {
    this.props.billingCycleImportUserSelect(e.target.name, e.target.checked)
  }

  onSubmit = (event) => {
    event.preventDefault()
    let selectedUsers = this.props.userResults
      .filter(user => this.props.selectedUsers.indexOf(user.Username) >= 0)
      .map((user) => {
        return user.Attributes.reduce((map, obj) => {
          map[obj.Name] = obj.Value
          return map
        })
      })

    this.props.billingCycleImportUserModalSaveAction(selectedUsers)
    this.props.billingCycleImportUserModalToggleAction()
  }

  renderBillingCycleList = () => {
    if (this.props.billingCycleItems.length === 0) {
      if (!this.props.isLoadingBillingCycleList) {
        let token = this.props.cognito.user.signInUserSession.idToken.jwtToken
        this.props.billingCycleListAction(token)
      }
      return
    }

    return this.props.billingCycleItems.map((item, index) => (
      <option
        key={index}
        value={item.billingCycleId}
        checked={item.billingCycleId === this.props.importBillingCycleId}>
        {item.name}
      </option>
    ))
  }

  renderUserList = () => {
    if (this.props.userResults.length === 0) {
      return
    }
    return this.props.userResults.map((item, index) => {
      let isChecked = false
      if (this.props.selectedUsers.indexOf(item.Username) >= 0) {
        isChecked = true
      }

      var userAttributes = item.Attributes.reduce((map, obj) => {
        map[obj.Name] = obj.Value
        return map
      })

      return (
        <div key={index} className="userImportItem">
          <Input
            type="checkbox"
            name={item.Username}
            checked={isChecked}
            onChange={this.onChangeUserSelected} />
          {userAttributes['given_name']} {userAttributes['family_name']} {userAttributes['phone_number']}
        </div>
      )
    })
  }

  render = () => {
    return (
      <Modal isOpen={this.props.isModalOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.props.billingCycleImportUserModalToggleAction}>Migrate Users</ModalHeader>
        <ModalBody>

          <FormGroup>
            <Label for="import">Import users from another billing cycle when it expires</Label>
            <Input type="select" name="import" id="import" onChange={this.onChangeBillingCycle}>
              <option
                value=''
                checked={!this.props.importBillingCycleId}>
                --- select ---
              </option>
              {this.renderBillingCycleList()}
            </Input>
          </FormGroup>

          <FormGroup>
            {this.renderUserList()}
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSubmit}>Save</Button>
          <Button color="secondary" onClick={this.props.billingCycleImportUserModalToggleAction}>Cancel</Button>
        </ModalFooter>
      </Modal >
    )
  }
}

const mapStateToProps = state => ({
  cognito: state.cognito,
  billingCycleItems: state.billingCycleReducer.items,

  isLoadingBillingCycleList: state.billingCycleReducer.isLoading,
  isLoadingUsers: state.billingCycleImportUserListReducer.isLoadingUsers,
  isModalOpen: state.billingCycleImportUserListReducer.isModalOpen,
  importBillingCycleId: state.billingCycleImportUserListReducer.importBillingCycleId,
  selectedUsers: state.billingCycleImportUserListReducer.selectedUsers,
  userResults: state.billingCycleImportUserListReducer.userResults,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    billingCycleImportUserModalSaveAction: billingCycleImportUserModalSaveAction,
    billingCycleImportUserSelect: billingCycleImportUserSelect,
    billingCycleListAction: billingCycleListAction,
    billingCycleImportUserModalToggleAction: billingCycleImportUserModalToggleAction,
    billingCycleImportUserQueryAction: billingCycleImportUserQueryAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycleImportUserListModal)