import React from 'react'
import { Confirm } from 'react-cognito'
import ConfirmForm from './ConfirmForm'

const confirmPage = () => (
  <div>
    <p>A confirmation code has been sent, please check your phone for a text message</p>
    <Confirm>
      <ConfirmForm />
    </Confirm>
  </div>
)

export default confirmPage