export const loginChangeUsernameAction = (event) => dispatch => {
  dispatch({
    type: 'LOGIN_CHANGE_USERNAME_ACTION',
    username: event.target.value
  })
}

export const loginChangePasswordAction = (event) => dispatch => {
  dispatch({
    type: 'LOGIN_CHANGE_PASSWORD_ACTION',
    password: event.target.value
  })
}

export const loginSubmitAction = () => dispatch => {
  dispatch({
    type: 'LOGIN_SUBMIT_ACTION'
  })
}

export const loginClearCache = () => dispatch => {
  dispatch({
    type: 'LOGIN_CLEAR_CACHE'
  })
}