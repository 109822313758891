const initialState = {
  oldPassword: '',
  newPassword: '',
  isChangingPassword: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_OLD_PASSWORD_INPUT_ACTION':
      return Object.assign({}, state, {
        oldPassword: action.oldPassword
      })
    case 'CHANGE_NEW_PASSWORD_INPUT_ACTION':
      return Object.assign({}, state, {
        newPassword: action.newPassword
      })
    case 'CHANGE_PASSWORD_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isChangingPassword: true
      })
    case 'CHANGE_PASSWORD_SUBMIT_SUCCESS_ACTION':
      return Object.assign({}, state, {
        isChangingPassword: false,
        oldPassword: '',
        newPassword: ''
      })
    case 'CHANGE_PASSWORD_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isChangingPassword: false
      })
    default:
      return state
  }
}