import React from 'react'
import { Route } from 'react-router'

import BillingCycles from './BillingCycles'
import BillingCycleForm from './BillingCycleForm'
import UserAdmin from './UserAdmin'

class Admin extends React.Component {
  render = () => {
    return (
      <div>
        <h1>Psfiring Admin <span role="img" aria-label="Tada">&#x1F36F;</span></h1>
        <Route exact path="/admin/billing_cycles" component={BillingCycles} />
        <Route exact path="/admin/billing_cycles/:id" component={BillingCycleForm} />
        <Route exact path="/admin/user_admin" component={UserAdmin} />
      </div>
    )
  }
}

export default Admin