import {
  authenticate,
  registerUser
} from 'react-cognito'
import { push } from 'connected-react-router'

export const registerChangePhoneAction = (event) => dispatch => {
  dispatch({
    type: 'REGISTER_CHANGE_PHONE_ACTION',
    phone: event.target.value
  })
}

export const registerChangeFirstNameAction = (event) => dispatch => {
  dispatch({
    type: 'REGISTER_CHANGE_FIRST_NAME_ACTION',
    firstName: event.target.value
  })
}

export const registerChangeLastNameAction = (event) => dispatch => {
  dispatch({
    type: 'REGISTER_CHANGE_LAST_NAME_ACTION',
    lastName: event.target.value
  })
}

export const registerChangePasswordAction = (event) => dispatch => {
  dispatch({
    type: 'REGISTER_CHANGE_PASSWORD_ACTION',
    password: event.target.value
  })
}

export const registerUserAction = (userRegistration) => dispatch => {
  dispatch({
    type: 'REGISTER_USER_ACTION'
  })

  registerUser(
    userRegistration.userPool,
    userRegistration.config,
    userRegistration.phone_number,
    userRegistration.password,
    {
      phone_number: userRegistration.phone_number,
      given_name: userRegistration.firstName,
      family_name: userRegistration.lastName,
    }
  ).then(
    () => dispatch(registerCompleteAction(userRegistration)),
    error => dispatch(registerErrorAction(error))
  )
}

export const registerCompleteAction = (userRegistration) => dispatch => {
  dispatch({
    type: 'REGISTER_COMPLETE_ACTION'
  })
  authenticate(
    userRegistration.phone_number,
    userRegistration.password,
    userRegistration.userPool,
    userRegistration.config,
    dispatch
  ).then(
    () => dispatch(registerChainLoginSuccessAction()),
    error => dispatch(registerErrorAction(error))
  )
}

export const registerErrorAction = (error) => dispatch => {
  dispatch({
    type: 'REGISTER_ERROR_ACTION',
    error: error
  })
}

export const registerChainLoginSuccessAction = () => dispatch => {
  dispatch(push('/'))
  dispatch({
    type: 'REGISTER_STAGE_2_LOGIN_SUCCESS_ACTION'
  })
}