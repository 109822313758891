import { combineReducers } from 'redux'
import { cognito } from 'react-cognito'

import billingCycleReducer from './billingCycleReducer'
import billingCycleFormReducer from './billingCycleFormReducer'
import billingCycleImportUserListReducer from './billingCycleImportUserListReducer'
import changePasswordFormReducer from './changePasswordFormReducer'
import firelogReportReducer from './firelogReportReducer'
import firingReducer from './firingReducer'
import loginFormReducer from './loginFormReducer'
import registerFormReducer from './registerFormReducer'
import resetPasswordFormReducer from './resetPasswordFormReducer'
import userAdminReducer from './userAdminReducer'
import userBalanceReducer from './userBalanceReducer'
import userBillingCycleReducer from './userBillingCycleReducer'
import verifyCodeFormReducer from './verifyCodeFormReducer'

export default combineReducers({
  billingCycleReducer,
  billingCycleFormReducer,
  billingCycleImportUserListReducer,
  cognito,
  changePasswordFormReducer,
  firelogReportReducer,
  firingReducer,
  loginFormReducer,
  registerFormReducer,
  resetPasswordFormReducer,
  userAdminReducer,
  userBalanceReducer,
  userBillingCycleReducer,
  verifyCodeFormReducer
})
