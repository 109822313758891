import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'

import {
  verifyCodeChangeAction,
  verifyCodeSubmitAction,
  verifyCodeCompleteAction,
  verifyCodeErrorAction
} from '../../actions/verifyCodeActions'

import Error from '../common/Error'

class ConfirmForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onResend: PropTypes.func,

    code: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    isVerifying: PropTypes.bool.isRequired,

    verifyCodeChangeAction: PropTypes.func.isRequired,
    verifyCodeSubmitAction: PropTypes.func.isRequired,
    verifyCodeCompleteAction: PropTypes.func.isRequired,
    verifyCodeErrorAction: PropTypes.func.isRequired,
  }

  /*eslint-disable no-unused-vars*/
  onSubmit = (event) => {
    event.preventDefault()
    this.props.verifyCodeSubmitAction()
    this.props.onSubmit(this.props.code)
      .then((user) => {
        this.props.verifyCodeCompleteAction()
      })
      .catch((error) => {
        this.props.verifyCodeErrorAction(error)
      })
  }

  onResend = (event) => {
    event.preventDefault()
    this.props.onResend()
      .then((user) => {
        this.props.verifyCodeErrorAction('Code resent')
      })
      .catch((error) => {
        this.props.verifyCodeErrorAction(error)
      })
  }
  /*eslint-enable no-unused-vars*/

  render = () => (
    <Form onSubmit={this.onSubmit}>
      <Error error={this.props.error} />
      <FormGroup>
        <Label for="code">Verification Code</Label>
        <Input
          id="code"
          disabled={this.props.isVerifying}
          onChange={this.props.verifyCodeChangeAction}
          value={this.props.code}
          required />
      </FormGroup>
      <ButtonGroup>
        <Button color="primary" type="submit" disabled={this.props.isVerifying}>Submit</Button>
        <Button type="button" onClick={this.onResend} disabled={this.props.isVerifying}>Resend code</Button>
        <Button type="button" onClick={this.props.onCancel} disabled={this.props.isVerifying}>Cancel</Button>
      </ButtonGroup>
    </Form>
  )
}

const mapStateToProps = state => ({
  code: state.verifyCodeFormReducer.code,
  error: state.verifyCodeFormReducer.error,
  isVerifying: state.verifyCodeFormReducer.isVerifying
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    verifyCodeChangeAction: verifyCodeChangeAction,
    verifyCodeSubmitAction: verifyCodeSubmitAction,
    verifyCodeCompleteAction: verifyCodeCompleteAction,
    verifyCodeErrorAction: verifyCodeErrorAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmForm)
