import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CognitoState } from 'react-cognito'
import { CircleLoader } from 'react-spinners'

import ConfirmPage from './ConfirmPage'
import LoggedInRouter from '../app/LoggedInRouter'
import LoggedOutPage from './LoggedOutPage'

class Dashboard extends React.Component {
  static propTypes = {
    state: PropTypes.string
  }

  render = () => {
    switch (this.props.state) {
      case CognitoState.LOGGED_IN:
      case CognitoState.EMAIL_VERIFICATION_REQUIRED:
        return <LoggedInRouter />
      case CognitoState.AUTHENTICATED:
      case CognitoState.LOGGING_IN:
        return <CircleLoader />
      case CognitoState.LOGGED_OUT:
        return <LoggedOutPage />
      case CognitoState.CONFIRMATION_REQUIRED:
        return <ConfirmPage />
      default:
        return (
          <div>
            <p>Unrecognised cognito state: {this.props.state}</p>
          </div>
        )
    }
  }
}

const mapStateToProps = state => ({
  state: state.cognito.state,
})

export default connect(mapStateToProps)(Dashboard)
