import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Logout } from 'react-cognito'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import AdminNav from './AdminNav'
import LogoutLink from '../cognito/LogoutLink'

class LoggedInNav extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    user: PropTypes.object.isRequired,
  }
  render() {
    var userName = this.props.user.signInUserSession.idToken.payload.given_name + ' ' + this.props.user.signInUserSession.idToken.payload.family_name
    return (
      <Nav className="ml-auto bs-dark" navbar>
        <NavItem><NavLink tag={Link} to="/">Home</NavLink></NavItem>
        {this.props.isAdmin ? <AdminNav /> : ''}
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {userName} 😊
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <NavLink tag={Link} to="/user/firelog_report">Firelog Report</NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink tag={Link} to="/user/change_password">Change Password</NavLink>
            </DropdownItem>
            <DropdownItem>
              <Logout><LogoutLink /></Logout>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user
})

export default connect(mapStateToProps)(LoggedInNav)