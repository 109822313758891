import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Tooltip,
  Label,
  Input
} from 'reactstrap'

import {
  firingTempChangeAction,
  firingLengthChangeAction,
  firingWidthChangeAction,
  firingHeightChangeAction,
  firingTotalChangeAction,
  firingPiecesChangeAction,
  firingSubmitAction,
  firingSubmitValidationErrorAction,
} from '../../actions/firingsActions'

import {
  logoutAction,
} from '../../actions/logoutActions'

import Error from '../common/Error'
import './StudioFiring.css'

const firingTemps = [
  { name: 'Low', color: 'warning' },
  { name: 'Mid', color: 'primary' },
  { name: 'High', color: 'danger' },
  { name: 'High-Own Glaze', color: 'danger' },
]

class StudioFiring extends React.Component {
  static propTypes = {

    // firingActions
    firingTempChangeAction: PropTypes.func.isRequired,
    firingLengthChangeAction: PropTypes.func.isRequired,
    firingWidthChangeAction: PropTypes.func.isRequired,
    firingHeightChangeAction: PropTypes.func.isRequired,
    firingTotalChangeAction: PropTypes.func.isRequired,
    firingPiecesChangeAction: PropTypes.func.isRequired,
    firingSubmitAction: PropTypes.func.isRequired,
    firingSubmitValidationErrorAction: PropTypes.func.isRequired,

    // logoutActions
    logoutAction: PropTypes.func.isRequired,

    // cognito
    user: PropTypes.object.isRequired,

    // firingReducer
    error: PropTypes.string.isRequired,
    fireTemp: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    pieces: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,

    // userBillingCycleReducer
    billingCycle: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,

    // userBalanceReducer
    balance: PropTypes.number.isRequired,
    overage: PropTypes.number.isRequired,
    isUserBalanceLoading: PropTypes.bool.isRequired,
    userBalanceError: PropTypes.string.isRequired,
  }


  calcTotal = () => {
    if (this.props.length + this.props.width + this.props.height === '') {
      return this.props.total
    }

    let length = parseInt(this.props.length, 10) || 0
    let width = parseInt(this.props.width, 10) || 0
    let height = parseInt(this.props.height, 10) + 2 || 0
    return length * width * height
  }

  calcPiecesTotal = () => {
    let total = this.calcTotal()
    let pieces = parseInt(this.props.pieces, 10) || 1
    return total * pieces
  }

  calcFireTempCost = () => {
    let unitVolume = this.calcPiecesTotal()
    let fireTempCostKey = 'rate' + (this.props.fireTemp === 'High-Own Glaze' ? 'HighOG' : this.props.fireTemp)
    if (!this.props.billingCycle || !unitVolume || !this.props.fireTemp) {
      return 0
    }
    let cost = this.props.billingCycle[fireTempCostKey] * unitVolume
    return cost.toFixed(2)
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.submit(null)
  }

  onSubmitAndLogout = (event) => {
    event.preventDefault()
    this.submit(this.logoutCallback)
  }

  logoutCallback = () => {
    const user = this.props.user
    user.signOut()
    this.props.logoutAction()
  }

  submit = (successCallback) => {
    if (!this.validateForm()) {
      this.props.firingSubmitValidationErrorAction()
      return
    }

    let userAttributes = this.props.user.signInUserSession.idToken.payload

    let data = {
      billingCycleId: userAttributes['name'],
      fireTemp: this.props.fireTemp,
      userId: this.props.user.username,
      unitVolume: this.calcPiecesTotal(),
      cost: this.calcFireTempCost(),
      pieces: this.props.pieces,
      name: userAttributes.given_name + ' ' + userAttributes.family_name,
      phone: userAttributes.phone_number
    }
    let token = this.props.user.signInUserSession.idToken.jwtToken
    this.props.firingSubmitAction(data, token, successCallback)
  }


  onTempSelectClick = (fireTemp) => {
    this.props.firingTempChangeAction(fireTemp)
  }

  validatePiecesTotal = () => {
    return this.calcPiecesTotal() > 0
  }

  validateFiringTemp = () => {
    return this.props.fireTemp !== ''
  }

  validateForm = () => {
    if (!this.validateFiringTemp()) { return false }
    if (!this.validatePiecesTotal()) { return false }
    return true
  }

  showValidationErrors = () => {
    return this.props.error !== ''
  }

  renderTempSelectButtons = () => {
    var self = this
    return firingTemps.map((fireTemp, val) => {
      let color = fireTemp.color
      return (
        <Button
          key={val}
          onClick={self.onTempSelectClick.bind(self, fireTemp.name)}
          color={color}
          outline={fireTemp.name !== self.props.fireTemp}>
          {fireTemp.name}
        </Button >
      )
    })
  }

  renderIncludedVolume = () => {
    if (!this.props.billingCycle || this.props.billingCycle['type'] !== 'Student') {
      return
    }

    return (
      <FormGroup className="inlineInput">
        <Label for="includedVolume">Included Volume</Label>
        <Input
          id="includedVolume"
          disabled={true}
          value={this.props.balance}
        />
      </FormGroup>
    )
  }

  render = () => (
    <Form onSubmit={this.onSubmit.bind(this)}>
      <Error message={this.props.error} />
      <h1>Firing Logger <span role="img" aria-label="fire">🔥</span></h1>
      <h5>See instructions below</h5>
      <h3>Fire Temperature</h3>
      <div id="buttonGroup">
        {this.renderTempSelectButtons()}
      </div>
      <Tooltip
        target="buttonGroup"
        placement="right"
        innerClassName="fireTempValidationTooltip"
        isOpen={this.showValidationErrors() && !this.validateFiringTemp()}>
        Required: select firing tempurature
      </Tooltip>
      <h3 className="projectDimensionHeader">Project Dimensions</h3>
      <FormGroup>
        <div className="StudioFiring">
          <FormGroup className="inlineInput">
            <Label for="length">Length</Label>
            <Input
              id="length"
              disabled={this.props.isSubmitting}
              onChange={this.props.firingLengthChangeAction}
              value={this.props.length}
            />
          </FormGroup>
          <FormGroup className="inlineInput">
            <Label for="width">Width</Label>
            <Input
              id="width"
              disabled={this.props.isSubmitting}
              onChange={this.props.firingWidthChangeAction}
              value={this.props.width}
            />
          </FormGroup>
          <FormGroup className="inlineInput">
            <Label for="height">Height*</Label>
            <Input
              id="height"
              disabled={this.props.isSubmitting}
              onChange={this.props.firingHeightChangeAction}
              value={this.props.height}
            />
          </FormGroup>
          <FormGroup className="inlineInput">
            <Label for="total">Volume</Label>
            <Input
              id="total"
              disabled={this.props.isSubmitting}
              onChange={this.props.firingTotalChangeAction}
              value={this.calcTotal()}
            />
          </FormGroup>
          <br />
          <FormGroup className="inlineInput">
            <Label for="pieces">Pieces</Label>
            <Input
              id="pieces"
              disabled={this.props.isSubmitting}
              onChange={this.props.firingPiecesChangeAction}
              value={this.props.pieces}
            />
          </FormGroup>
          <FormGroup className="inlineInput">
            <Label for="piecestotal">Grand Total</Label>
            <Input
              id="piecestotal"
              disabled={true}
              value={this.calcPiecesTotal()}
              invalid={this.showValidationErrors() && !this.validatePiecesTotal()}
            />
            <FormFeedback tooltip>Grand total must be greater than zero.</FormFeedback>
          </FormGroup>
          {this.renderIncludedVolume()}
          <FormGroup className="inlineInput">
            <Label for="cost">Cost</Label>
            <Input
              id="cost"
              disabled={true}
              value={this.calcFireTempCost()}
            />
            <FormFeedback tooltip>Grand total must be greater than zero.</FormFeedback>
          </FormGroup>
        </div>
      </FormGroup>
      <Button color="primary" type="submit" disabled={this.props.isSubmitting}>Submit and Add More</Button> {' '}
      <Button color="danger" disabled={this.props.isSubmitting} onClick={this.onSubmitAndLogout}>Submit and Logout</Button>
      <h2>Instructions</h2>
      <h5>1. Select the temperature at which you need to have the piece(s) fired.</h5>
      <h5>2. Enter the volume of the piece(s) by either:</h5>
      <p className = "ml-3">a. Entering the length, width, and height of the your piece(s). *Note: Two inches will be automatically added to the height to take the shelf into account.</p>
      <p className = "ml-3">b. Using the studio volume chart, you may simply enter the total. *Note: two inches were added to the height by the chart.</p>
      <p className = "ml-3">c. Entering the total only, measuring the piece(s) and doing the math yourself. *Note: You must add 2 inches to the height before multiplying. </p>
      <h5>3. Enter the number of pieces you have of this size into the "pieces" field, if you have more than one piece of the same size.</h5>
      <h5>4. Click ‘Submit and Add More’ to enter more pieces. Click the ‘Submit and Logout’ to submit and immediately log out.</h5>
      <h5>5. A tag will print a few seconds after each submission. You can check to see if your submission was recorded in the ‘Firelog Report’ under your name in the top right corner.</h5>
      <h5>Be sure to log out when complete. There is a ‘loggout’ button under your name in the top right corner. </h5>
      <h5>If you have any problems, please contact staff.</h5>
    </Form>
  )
}

const mapStateToProps = state => ({
  
  // firingReducer
  user: state.cognito.user,
  error: state.firingReducer.error,
  fireTemp: state.firingReducer.fireTemp,
  length: state.firingReducer.length,
  width: state.firingReducer.width,
  height: state.firingReducer.height,
  total: state.firingReducer.total,
  pieces: state.firingReducer.pieces,
  isSubmitting: state.firingReducer.isSubmitting,

  // userBalanceReducer
  balance: state.userBalanceReducer.balance,
  overage: state.userBalanceReducer.overage,
  isUserBalanceLoading: state.userBalanceReducer.isUserBalanceLoading,
  userBalanceError: state.userBalanceReducer.error,

  //userBillingCycleReducer
  billingCycle: state.userBillingCycleReducer.billingCycle,
  isLoading: state.userBillingCycleReducer.isLoading,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    firingTempChangeAction: firingTempChangeAction,
    firingLengthChangeAction: firingLengthChangeAction,
    firingWidthChangeAction: firingWidthChangeAction,
    firingHeightChangeAction: firingHeightChangeAction,
    firingTotalChangeAction: firingTotalChangeAction,
    firingPiecesChangeAction: firingPiecesChangeAction,
    firingSubmitAction: firingSubmitAction,
    firingSubmitValidationErrorAction: firingSubmitValidationErrorAction,
    logoutAction: logoutAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioFiring)