const initialState = {
  error: '',
  isSubmitting: false,
  isModalOpen: false,
  modalUser: null,
  searchAttribute: 'given_name',
  searchTerm: '',
  users: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'USER_ADMIN_SEARCH_ATTRIBUTE_CHANGE_ACTION':
      return Object.assign({}, state, {
        searchAttribute: action.searchAttribute
      })
    case 'USER_ADMIN_SEARCH_TERM_CHANGE_ACTION':
      return Object.assign({}, state, {
        searchTerm: action.searchTerm
      })
    case 'USER_ADMIN_SEARCH_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isSubmitting: true
      })
    case 'USER_ADMIN_SEARCH_SUCCESS_ACTION':
      return Object.assign({}, state, {
        error: '',
        isSubmitting: false,
        isModalOpen: false,
        modalUser: null,
        users: action.result.Users,
      })
    case 'USER_ADMIN_SEARCH_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error.message,
        isSubmitting: false,
      })
    case 'USER_ADMIN_RAISE_MODAL_ACTION':
      return Object.assign({}, state, {
        isModalOpen: true,
        modalUser: action.user
      })
    case 'USER_ADMIN_CLOSE_MODAL_ACTION':
      return Object.assign({}, state, {
        isModalOpen: false,
        modalUser: null
      })
    case 'USER_ADMIN_SET_BILLING_CYCLE_SUCCESS_ACTION':
      return Object.assign({}, state, {
        error: '',
        isSubmitting: false,
        isModalOpen: false,
        modalUser: null,
        searchAttribute: 'given_name',
        searchTerm: '',
        users: null
      })
    default:
      return state
  }
}