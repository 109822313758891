const initialState = {
  error: '',
  isLoadingUsers: false,
  isModalOpen: false,
  importBillingCycleId: '',
  selectedUsers: [],
  userResults: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BILLING_CYCLE_IMPORT_USER_MODAL_TOGGLE_ACTION':
      return Object.assign({}, state, {
        isModalOpen: !state.isModalOpen
      })
    case 'BILLING_CYCLE_IMPORT_USER_SELECT':
      var selectedUsers = Object.assign([], state.selectedUsers)
      if (action.isSelected) {
        // add the user to the list
        selectedUsers.push(action.username)
      } else {
        // remove the user
        selectedUsers.splice(selectedUsers.indexOf(action.username), 1)
      }
      return Object.assign({}, state, {
        selectedUsers: selectedUsers
      })
    case 'BILLING_CYCLE_IMPORT_USER_QUERY_ACTION':
      return Object.assign({}, state, {
        isLoadingUsers: true,
        isModalOpen: true,
      })
    case 'BILLING_CYCLE_IMPORT_USER_QUERY_SUCCESS_ACTION':
      return Object.assign({}, state, {
        isLoadingUsers: false,
        userResults: action.users,
        selectedUsers: action.selectedUsers,
      })
    case 'BILLING_CYCLE_IMPORT_USER_QUERY_ERROR_ACTION':
      return Object.assign({}, state, {
        isLoadingUsers: false,
        error: action.error
      })
    default:
      return state
  }
}