const initialState = {
  code: '',
  error: '',
  message: '',
  password: '',
  phone: '',
  isPasswordResetting: false,
  isRequestingResetCode: false,

}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_PASSWORD_PHONE_INPUT_CHANGE_ACTION':
      return Object.assign({}, state, {
        phone: action.phone
      })
    case 'RESET_PASSWORD_VERIFICATION_INPUT_CHANGE_ACTION':
      return Object.assign({}, state, {
        code: action.code
      })
    case 'RESET_PASSWORD_INPUT_CHANGE_ACTION':
      return Object.assign({}, state, {
        password: action.password
      })
    case 'RESET_PASSWORD_VERIFICTION_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isRequestingResetCode: true
      })
    case 'RESET_PASSWORD_VERIFICATION_SUCCESS_ACTION':
      return Object.assign({}, state, {
        isRequestingResetCode: false,
        message: action.message,
        error: '',
      })
    case 'RESET_PASSWORD_VERIFICATION_ERROR_ACTION':
      return Object.assign({}, state, {
        isRequestingResetCode: false,
        message: '',
        error: action.error,
      })
    case 'RESET_PASSWORD_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isPasswordResetting: true
      })
    case 'RESET_PASSWORD_SUCCESS_ACTION':
      return Object.assign({}, state, {
        message: action.message,
        code: '',
        error: '',
        password: '',
        phone: '',
        isPasswordResetting: false,
      })
    case 'RESET_PASSWORD_ERROR_ACTION':
      return Object.assign({}, state, {
        message: '',
        error: action.error,
      })
    default:
      return state
  }
}
