const initialState = {
  error: '',
  hasMore: true,
  items: [],
  isLoading: false,
}

export default (state = initialState, action) => {
  var items, item, index
  switch (action.type) {
    case 'BILLING_CYCLE_LIST_ACTION':
    case 'BILLING_CYCLE_FETCH_ACTION':
      return Object.assign({}, state, {
        isLoading: true
      })
    case 'BILLING_CYCLE_FETCH_RESULT_ACTION':
      items = Object.assign([], state.items) // make a new object for items
      item = items.find(i => i.billingCycleId === action.item.billingCycleId) // find the item to update
      index = items.indexOf(item) // get the index
      if (index > -1) {
        items[index] = action.item // replace the item with the updated version if it exists
      } else {
        items.push(action.item) // append the new item to the end
      }
      return Object.assign({}, state, {
        items: items,
        isLoading: false,
      })
    case 'BILLING_CYCLE_FETCH_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
      })
    case 'BILLING_CYCLE_LIST_RESULTS_ACTION':
      return Object.assign({}, state, {
        items: state.items.concat(action.items),
        isLoading: false,
      })
    case 'BILLING_CYCLE_LIST_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
      })
    case 'BILLING_CYCLE_SUBMIT_SUCCESS_ACTION':
      items = Object.assign([], state.items) // make a new object for items
      item = items.find(i => i.billingCycleId === action.item.billingCycleId) // find the item to update
      index = items.indexOf(item) // get the index
      if (index > -1) {
        items[index] = action.item // replace the item with the updated version if it exists
      } else {
        items.push(action.item) // append the new item to the end
      }
      return Object.assign({}, state, {
        items: items
      })
    default:
      return state
  }
}