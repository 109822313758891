const initialState = {
  phone: '',
  firstName: '',
  lastName: '',
  password: '',
  isRegistering: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER_CHANGE_PHONE_ACTION':
      return Object.assign({}, state, {
        phone: action.phone
      })
    case 'REGISTER_CHANGE_FIRST_NAME_ACTION':
      return Object.assign({}, state, {
        firstName: action.firstName
      })
    case 'REGISTER_CHANGE_LAST_NAME_ACTION':
      return Object.assign({}, state, {
        lastName: action.lastName
      })
    case 'REGISTER_CHANGE_PASSWORD_ACTION':
      return Object.assign({}, state, {
        password: action.password
      })
    case 'REGISTER_USER_ACTION':
      return Object.assign({}, state, {
        isRegistering: true,
        error: ''
      })
    case 'REGISTER_COMPLETE_ACTION':
      return Object.assign({}, state, {
        phone: '',
        firstName: '',
        lastName: '',
        password: '',
        isRegistering: false,
        error: ''
      })
    case 'REGISTER_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isRegistering: false
      })
    default:
      return state
  }
}
