const initialState = {
  isUserBalanceLoading: false,
  balance: 0,
  overage: 0,
  error: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'USER_FETCH_BALANCE_ACTION':
      return Object.assign({}, state, {
        isUserBalanceLoading: true
      })
    case 'USER_FETCH_BALANCE_RESULT_ACTION':
      return Object.assign({}, state, {
        balance: action.balance,
        overage: action.overage,
      })
    case 'USER_FETCH_BALANCE_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error
      })
    default:
      return state
  }
}