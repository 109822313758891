import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import Error from '../common/Error'

import {
  firelogReportFetchAction
} from '../../actions/firelogReportActions'

import './FirelogReport.css'

class FirelogReport extends React.Component {
  static propTypes = {
    // cognito
    user: PropTypes.object.isRequired,

    // firelogReportReducer
    error: PropTypes.string.isRequired,
    firelogReportFetchAction: PropTypes.func.isRequired,
    firelogs: PropTypes.array.isRequired,
    hasFetched: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }

  componentDidMount = () => {
    if (!this.props.isFetching && !this.props.hasFetched) {
      let token = this.props.user.signInUserSession.idToken.jwtToken
      this.props.firelogReportFetchAction(token)
    }
  }

  render() {
    var unitVolueTotal = 0
    var costTotal = 0
    return (
      <div>
        <h1>Fire Log</h1>
        <Error message={this.props.error} />
        <div className="divTable greyGridTable">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead">Date</div>
              <div className="divTableHead">Temp</div>
              <div className="divTableHead">Unit Volume</div>
              <div className="divTableHead">Cost</div>
            </div>
          </div>
          {this.props.firelogs.map((item, index) => {
            var date = moment.unix(item.createdAt / 1000).format('LLLL')
            unitVolueTotal += item.unitVolume
            costTotal += item.cost
            return (
              <div key={index} className="divTableRow">
                <div className="divTableCell">{date}</div>
                <div className="divTableCell">{item.fireTemp}</div>
                <div className="divTableCell">{item.unitVolume}</div>
                <div className="divTableCell cost">{item.cost.toFixed(2)}</div>
              </div>
            )
          }).reverse()}
          <div className="divTableRow" style={{ fontWeight: 'bold' }}>
            <div className="divTableCell"></div>
            <div className="divTableCell" style={{ textAlign: 'right' }}>Total:</div>
            <div className="divTableCell">{unitVolueTotal} inches</div>
            <div className="divTableCell">$ {costTotal.toFixed(2)}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user,
  error: state.firelogReportReducer.error,
  firelogs: state.firelogReportReducer.firelogs,
  hasFetched: state.firelogReportReducer.hasFetched,
  isFetching: state.firelogReportReducer.isFetching,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    firelogReportFetchAction: firelogReportFetchAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FirelogReport)
