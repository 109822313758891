const initialState = {
  username: '',
  password: '',
  isLoggingIn: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_CHANGE_USERNAME_ACTION':
      return Object.assign({}, state, {
        username: action.username
      })
    case 'LOGIN_CHANGE_PASSWORD_ACTION':
      return Object.assign({}, state, {
        password: action.password
      })
    case 'LOGIN_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isLogginIn: true
      })
    case 'LOGIN_CLEAR_CACHE':
      return Object.assign({}, state, {
        username: '',
        password: '',
        isLoggingIn: false,
        error: ''
      })
    default:
      return state
  }
}
