import { changePassword } from 'react-cognito'

export const changeOldPasswordInputAction = (event) => dispatch => {
  dispatch({
    type: 'CHANGE_OLD_PASSWORD_INPUT_ACTION',
    oldPassword: event.target.value
  })
}

export const changeNewPasswordInputAction = (event) => dispatch => {
  dispatch({
    type: 'CHANGE_NEW_PASSWORD_INPUT_ACTION',
    newPassword: event.target.value
  })
}

export const changePasswordSubmitAction = (passwordChange) => dispatch => {
  dispatch({
    type: 'CHANGE_PASSWORD_SUBMIT_ACTION'
  })

  changePassword(
    passwordChange.user,
    passwordChange.oldPassword,
    passwordChange.newPassword
  ).then(
    () => dispatch(changePasswordSubmitSuccessAction()),
    error => dispatch(changePasswordErrorAction(error))
  )
}

export const changePasswordSubmitSuccessAction = () => dispatch => {
  dispatch({
    type: 'CHANGE_PASSWORD_SUBMIT_SUCCESS_ACTION'
  })
}

export const changePasswordErrorAction = (error) => dispatch => {
  dispatch({
    type: 'CHANGE_PASSWORD_ERROR_ACTION',
    error: error
  })
}