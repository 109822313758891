import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'

import {
  resetPasswordPhoneInputChangeAction,
  resetPasswordVerificationInputChangeAction,
  resetPasswordInputChangeAction,
  resetPasswordVerificationSubmitAction,
  resetPasswordSubmitAction
} from '../../actions/resetPasswordActions'

import { phoneCheck } from '../../lib/phone'
import Error from '../common/Error'

class PasswordResetForm extends React.Component {
  static propTypes = {
    // Cognito PasswordResetPage props
    sendVerificationCode: PropTypes.func,
    setPassword: PropTypes.func,

    // resetPasswordActions
    resetPasswordPhoneInputChangeAction: PropTypes.func.isRequired,
    resetPasswordVerificationInputChangeAction: PropTypes.func.isRequired,
    resetPasswordInputChangeAction: PropTypes.func.isRequired,
    resetPasswordVerificationSubmitAction: PropTypes.func.isRequired,
    resetPasswordSubmitAction: PropTypes.func.isRequired,

    // resetPasswordFormReducer
    code: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    isPasswordResetting: PropTypes.bool.isRequired,
    isRequestingResetCode: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }

  sendVerificationCode = (event) => {
    event.preventDefault()
    this.props.resetPasswordVerificationSubmitAction(phoneCheck(this.props.phone), this.props.sendVerificationCode)
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.resetPasswordSubmitAction({
      username: phoneCheck(this.props.phone),
      code: this.props.code,
      password: this.props.password,
    }, this.props.setPassword)
  }

  render = () => (
    <div>
      <Error message={this.props.message} color="info" />
      <Error message={this.props.error} />
      <Form onSubmit={this.sendVerificationCode}>
        <FormGroup>
          <Label for="phone">Phone Number</Label>
          <Input
            id="phone"
            type="number"
            placeholder="Phone Number"
            disabled={this.props.isRequestingResetCode}
            onChange={this.props.resetPasswordPhoneInputChangeAction}
            value={this.props.phone}
            required />
        </FormGroup>
        <Button color="primary" type="submit" disabled={this.props.isRequestingResetCode}>Send verification code to phone</Button>
      </Form>

      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label for="code">Verification Code</Label>
          <Input
            id="code"
            type="number"
            placeholder="Verification Code"
            disabled={this.props.isPasswordResetting}
            onChange={this.props.resetPasswordVerificationInputChangeAction}
            value={this.props.code}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="password">New Password</Label>
          <Input
            id="password"
            type="password"
            placeholder="New Password"
            disabled={this.props.isPasswordResetting}
            onChange={this.props.resetPasswordInputChangeAction}
            value={this.props.password}
            required />
        </FormGroup>
        <Button color="primary" type="submit" disabled={this.props.isPasswordResetting}>Set new password</Button>
      </Form>
    </div>
  )
}

const mapStateToProps = state => ({
  code: state.resetPasswordFormReducer.code,
  error: state.resetPasswordFormReducer.error,
  isPasswordResetting: state.resetPasswordFormReducer.isPasswordResetting,
  isRequestingResetCode: state.resetPasswordFormReducer.isRequestingResetCode,
  message: state.resetPasswordFormReducer.message,
  password: state.resetPasswordFormReducer.password,
  phone: state.resetPasswordFormReducer.phone,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordPhoneInputChangeAction: resetPasswordPhoneInputChangeAction,
    resetPasswordVerificationInputChangeAction: resetPasswordVerificationInputChangeAction,
    resetPasswordInputChangeAction: resetPasswordInputChangeAction,
    resetPasswordVerificationSubmitAction: resetPasswordVerificationSubmitAction,
    resetPasswordSubmitAction: resetPasswordSubmitAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm)