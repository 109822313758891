import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { CircleLoader } from 'react-spinners'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router' //react-router v4

import {
  userBillingCycleFetchAction
} from '../../actions/billingCycleActions'

import {
  userFetchBalanceAction
} from '../../actions/userBalanceActions'

import Admin from '../admin/Admin'
import ChangePasswordForm from '../cognito/ChangePasswordForm'
import ExpiredBillingCycle from '../firings/ExpiredBillingCycle'
import FirelogReport from '../firelog_report/FirelogReport'
import StudioFiring from '../firings/StudioFiring'


class LoggedInRouter extends React.Component {

  static propTypes = {
    // billingCycleActions
    userBillingCycleFetchAction: PropTypes.func.isRequired,

    // userBalanceActions
    userFetchBalanceAction: PropTypes.func.isRequired,

    // userBillingCycleReducer
    billingCycle: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,

    // cognito
    user: PropTypes.object.isRequired,

    // userBalanceReducer
    balance: PropTypes.number.isRequired,
    overage: PropTypes.number.isRequired,
    isUserBalanceLoading: PropTypes.bool.isRequired,
    userBalanceError: PropTypes.string.isRequired,
  }

  componentDidMount = () => {
    let billingCycleId = this.props.user.signInUserSession.idToken.payload.name
    let token = this.props.user.signInUserSession.idToken.jwtToken
    if (!this.props.billingCycle && !this.props.isLoading) {
      this.props.userBillingCycleFetchAction(billingCycleId, token)
    }
    if (!this.props.balance && !this.props.isUserBalanceLoading) {
      this.props.userFetchBalanceAction(token)
    }
  }

  isBillingCycleExpired = () => {
    //if the billing cycle is expired or non existant, return true
    if(!this.props.billingCycle) {
      return true
    }
    let now = new Date() 
    if (this.props.billingCycle.endDate < now.getTime() /1000){
      return true
    }
    return false
  }

  render = () => (
    <div>
      <Route exact path="/user/change_password" component={ChangePasswordForm} />
      <Route exact path="/user/firelog_report" component={FirelogReport} />
      <Route path="/admin" component={Admin} />
      {this.props.isLoading ?
        <CircleLoader /> : 
        this.isBillingCycleExpired() ?
          <Route exact path="/" component={ExpiredBillingCycle} /> :
          <Route exact path="/" component={StudioFiring} />}
    </div>
  )
}

const mapStateToProps = state => ({

  // firingReducer
  user: state.cognito.user,

  // userBillingCycleReducer
  billingCycle: state.userBillingCycleReducer.billingCycle,
  isLoading: state.userBillingCycleReducer.isLoading,

  // userBalanceReducer
  balance: state.userBalanceReducer.balance,
  overage: state.userBalanceReducer.overage,
  isUserBalanceLoading: state.userBalanceReducer.isUserBalanceLoading,
  userBalanceError: state.userBalanceReducer.error,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userBillingCycleFetchAction: userBillingCycleFetchAction,
    userFetchBalanceAction: userFetchBalanceAction
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedInRouter))
