import moment from 'moment'
import { push } from 'connected-react-router'
import { apiUrl } from '../lib/api'

export const billingCycleFormGetById = (id, token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_GET_BY_ID'
  })
  fetch(apiUrl() + '/billing_cycle/' + id,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Authorization': token,
      }
    })
    .then((response) => {
      return response.json()
    })
    .then((results) => {
      dispatch(billingCycleFormSetProps(results))
    })
    .catch((error) => {
      dispatch(billingCycleSubmitErrorAction(error))
    })
}

export const billingCycleFormSetProps = (item) => dispatch => {
  let billingCycleItem = Object.assign({}, item, {
    startDate: moment.unix(item.startDate).format('YYYY-MM-DD'),
    endDate: moment.unix(item.endDate).format('YYYY-MM-DD'),
  })
  dispatch({
    type: 'BILLING_CYCLE_SET_PROPS',
    item: billingCycleItem
  })
}

export const billingCycleNameChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_NAME_CHANGE_ACTION',
    name: event.target.value
  })
}

export const billingCycleTypeChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_TYPE_CHANGE_ACTION',
    typeValue: event.target.value
  })
}

export const billingCycleStartDateChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_START_DATE_CHANGE_ACTION',
    startDate: event.target.value
  })
}

export const billingCycleEndDateChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_END_DATE_CHANGE_ACTION',
    endDate: event.target.value
  })
}

export const billingCycleRateLowChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_RATE_LOW_CHANGE_ACTION',
    rateLow: event.target.value
  })
}

export const billingCycleRateMidChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_RATE_MID_CHANGE_ACTION',
    rateMid: event.target.value
  })
}

export const billingCycleRateHighChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_RATE_HIGH_CHANGE_ACTION',
    rateHigh: event.target.value
  })
}

export const billingCycleRateHighOGChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_RATE_HIGHOG_CHANGE_ACTION',
    rateHighOG: event.target.value
  })
}

export const billingCycleIncludedVolumeChangeAction = (event) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_INCLUDED_VOLUME_CHANGE_ACTION',
    includedVolume: event.target.value
  })
}

export const billingCycleSubmitAction = (data, token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_SUBMIT_ACTION'
  })
  fetch(apiUrl() + '/billing_cycle',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Authorization': token,
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.json()
    })
    .then((result) => {
      dispatch(billingCycleSubmitSuccessAction(result))
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(billingCycleSubmitErrorAction(errorMessage.message))
      })
    })
}

export const billingCycleSubmitUpdateAction = (billingCycleId, data, token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_SUBMIT_UPDATE_ACTION'
  })
  fetch(apiUrl() + '/billing_cycle/' + billingCycleId,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Authorization': token,
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.json()
    })
    .then((result) => {
      dispatch(billingCycleSubmitSuccessAction(result))
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(billingCycleSubmitErrorAction(errorMessage.message))
      })
    })
}

export const billingCycleSubmitSuccessAction = (item) => dispatch => {
  dispatch(push('/admin/billing_cycles'))
  dispatch({
    type: 'BILLING_CYCLE_SUBMIT_SUCCESS_ACTION',
    item: item
  })
}

export const billingCycleSubmitErrorAction = (error) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_SUBMIT_ERROR_ACTION',
    error: error
  })
}