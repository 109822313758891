import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import {
  Button
} from 'reactstrap'

import {
  billingCycleListAction
} from '../../actions/billingCycleActions'

import BillingCycleListItem from './BillingCycleListItem'
import Error from '../common/Error'

import './BillingCycleDivTable.css'

class BillingCycles extends React.Component {
  static propTypes = {
    // billinCycleActions
    billingCycleListAction: PropTypes.func.isRequired,

    // cognito
    user: PropTypes.object.isRequired,

    // billingCycleReducer
    error: PropTypes.string.isRequired,
    hasMore: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
  }

  componentDidMount() {
    if (this.props.items.length === 0) {
      let token = this.props.user.signInUserSession.idToken.jwtToken
      this.props.billingCycleListAction(token)
    }
  }

  render() {
    return (
      <div>
        <Error error={this.props.error} />
        <p>In order to create a new Billing cycle, select the create new button on the top left. Fill in all applicable fields and press submit. If the billing cycle does not appear in the list after submitting, simply refresh the page.</p>
        <p>Once you create a new billing cycle, you can go to the user admin portal and move the users into the new billing cycle. Currently, this should be done on on the day of the transition. (An automatic bulk move tool is being developed as a top priority additional feature.)</p>
        <p>When it is time to download a report for a particular billing cycle, select the ‘Download CSV’ button for the corresponding billing cycle. </p>
        <p>Please email feature requests to potters.studio.printer@gmail.com</p>
        <Link to="/admin/billing_cycles/new"><Button color="warning">Create new</Button></Link>
        <div>
          <InfiniteScroll
            next={this.fetchMoreData}
            hasMore={this.props.hasMore}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>That is all of them...</b>
              </p>
            }>
            <div className="divTable greyGridTable">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Billing Cycle Name</div>
                  <div className="divTableHead">Type</div>
                  <div className="divTableHead">Start Date</div>
                  <div className="divTableHead">End Date</div>
                  <div className="divTableHead">Download CSV</div>
                </div>
              </div>
              {this.props.items.map((item, index) => (
                <BillingCycleListItem item={item} key={index} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user,
  error: state.billingCycleReducer.error,
  hasMore: state.billingCycleReducer.hasMore,
  items: state.billingCycleReducer.items,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    billingCycleListAction: billingCycleListAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycles)