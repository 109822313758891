import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

import {
  changeOldPasswordInputAction,
  changeNewPasswordInputAction,
  changePasswordSubmitAction
} from '../../actions/changePasswordActions'

import Error from '../common/Error'

class ChangePasswordForm extends React.Component {

  static propTypes = {
    changeOldPasswordInputAction: PropTypes.func.isRequired,
    changeNewPasswordInputAction: PropTypes.func.isRequired,
    changePasswordSubmitAction: PropTypes.func.isRequired,

    user: PropTypes.object.isRequired,
    isChangingPassword: PropTypes.bool.isRequired,
    oldPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.changePasswordSubmitAction({
      user: this.props.user,
      oldPassword: this.props.oldPassword,
      newPassword: this.props.newPassword
    })
  }

  render = () => {
    return (
      <Form onSubmit={this.onSubmit}>
        <Error error={this.props.error} />
        <FormGroup>
          <Label for="oldPassword">Old Password</Label>
          <Input
            id="oldPassword"
            type="password"
            placeholder="old password"
            disabled={this.props.isChangingPassword}
            onChange={this.props.changeOldPasswordInputAction}
            value={this.props.oldPassword}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="newPassword">New Password</Label>
          <Input
            id="newPassword"
            type="password"
            placeholder="new password"
            disabled={this.props.isChangingPassword}
            onChange={this.props.changeNewPasswordInputAction}
            value={this.props.newPassword}
            required />
        </FormGroup>
        <Button color="primary" type="submit" disabled={this.props.isChangingPassword}>Set new password</Button>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  // changePasswordFormReducer
  isChangingPassword: state.changePasswordFormReducer.isChangingPassword,
  oldPassword: state.changePasswordFormReducer.oldPassword,
  newPassword: state.changePasswordFormReducer.newPassword,
  error: state.changePasswordFormReducer.error,

  // cognito 
  state: state.cognito.state,
  user: state.cognito.user
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeOldPasswordInputAction: changeOldPasswordInputAction,
    changeNewPasswordInputAction: changeNewPasswordInputAction,
    changePasswordSubmitAction: changePasswordSubmitAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
