const initialState = {
  name: '',
  type: '',
  startDate: '',
  endDate: '',
  rateLow: '',
  rateMid: '',
  rateHigh: '',
  rateHighOG: '',
  includedVolume: '0',
  isSubmitting: false,
  userImportList: [],
  error: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BILLING_CYCLE_GET_BY_ID':
      return Object.assign({}, state, {
        isSubmitting: true,
        error: ''
      })
    case 'BILLING_CYCLE_SET_PROPS':
      return Object.assign({}, state, {
        name: action.item.name,
        type: action.item.type,
        startDate: action.item.startDate,
        endDate: action.item.endDate,
        rateLow: String(action.item.rateLow),
        rateMid: String(action.item.rateMid),
        rateHigh: String(action.item.rateHigh),
        rateHighOG: String(action.item.rateHighOG),
        includedVolume: String(action.item.includedVolume),
        isSubmitting: false,
        userImportList: action.item.userImportList ? action.item.userImportList : [],
        error: ''
      })
    case 'BILLING_CYCLE_NAME_CHANGE_ACTION':
      return Object.assign({}, state, {
        name: action.name
      })
    case 'BILLING_CYCLE_TYPE_CHANGE_ACTION':
      return Object.assign({}, state, {
        type: action.typeValue
      })
    case 'BILLING_CYCLE_START_DATE_CHANGE_ACTION':
      return Object.assign({}, state, {
        startDate: action.startDate
      })
    case 'BILLING_CYCLE_END_DATE_CHANGE_ACTION':
      return Object.assign({}, state, {
        endDate: action.endDate
      })
    case 'BILLING_CYCLE_RATE_LOW_CHANGE_ACTION':
      return Object.assign({}, state, {
        rateLow: action.rateLow
      })
    case 'BILLING_CYCLE_RATE_MID_CHANGE_ACTION':
      return Object.assign({}, state, {
        rateMid: action.rateMid
      })
    case 'BILLING_CYCLE_RATE_HIGH_CHANGE_ACTION':
      return Object.assign({}, state, {
        rateHigh: action.rateHigh
      })
    case 'BILLING_CYCLE_RATE_HIGHOG_CHANGE_ACTION':
      return Object.assign({}, state, {
        rateHighOG: action.rateHighOG
      })
    case 'BILLING_CYCLE_INCLUDED_VOLUME_CHANGE_ACTION':
      return Object.assign({}, state, {
        includedVolume: action.includedVolume
      })
    case 'BILLING_CYCLE_IMPORT_USER_MODAL_SAVE_ACTION':
      return Object.assign({}, state, {
        userImportList: state.userImportList.concat(action.selectedUsers)
      })
    case 'BILLING_CYCLE_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isSubmitting: true
      })
    case 'BILLING_CYCLE_SUBMIT_SUCCESS_ACTION':
      return Object.assign({}, state, {
        name: '',
        type: '',
        startDate: '',
        endDate: '',
        rateLow: '',
        rateMid: '',
        rateHigh: '',
        rateHighOG: '',
        includedVolume: '0',
        isSubmitting: false,
        userImportList: [],
      })
    case 'BILLING_CYCLE_SUBMIT_ERROR_ACTION':
      return Object.assign({}, state, {
        isSubmitting: false,
        error: action.error
      })
    default:
      return state
  }
}