/*what we need to do is 
when the user logs in, it needs to check for a billing cycle and then fetch the user billing cycle metadata from the api.

usecase, when the user logs out, this needs to clear*/

const initialState = {
  error: '',
  billingCycle: null,
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'USER_BILLING_CYCLE_FETCH_ACTION':
      return Object.assign({}, state, {
        isLoading: true
      })
    case 'USER_BILLING_CYCLE_FETCH_RESULT_ACTION':
      return Object.assign({}, state, {
        billingCycle: action.billingCycle,
        isLoading: false,
      })
    case 'COGNITO_LOGOUT':
      return Object.assign({}, state, {
        billingCycle: null,
      })
    case 'USER_BILLING_CYCLE_FETCH_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
      })
    default:
      return state
  }
}