import React from 'react'

class ExpiredBillingCycle extends React.Component {
  render = () => (
    <div>
      <h1>Billing Cycle Expired</h1>
      <p> </p>
      <h2>You are not currently registered for a class or membership according to this database. </h2>
      <h2>Please contact staff for assistance.</h2>
    </div>
  )
}

export default ExpiredBillingCycle