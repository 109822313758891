import { apiUrl } from '../lib/api'

export const userFetchBalanceAction = (token) => dispatch => {
  dispatch({
    type: 'USER_FETCH_BALANCE_ACTION'
  })

  fetch(apiUrl() + '/user_balance', 
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': token
      }
    })
    .then((response) => response.json())
    .then((result) => {
      dispatch(userFetchBalanceResultAction(result))
    })
    .catch((error) => {
      dispatch(userFetchBalanceErrorAction(error))
    })
}

export const userFetchBalanceResultAction = (result) => dispatch => {
  dispatch({
    type: 'USER_FETCH_BALANCE_RESULT_ACTION',
    balance: result.balance,
    overage: result.overage,
  })
}

export const userFetchBalanceErrorAction = (error) => dispatch => {
  dispatch({
    type: 'USER_FETCH_BALANCE_ERROR_ACTION',
    error: error,
  })
}