export const verifyCodeChangeAction = (event) => dispatch => {
  dispatch({
    type: 'VERIFY_CODE_CHANGE_ACTION',
    code: event.target.value
  })
}

export const verifyCodeSubmitAction = () => dispatch => {
  dispatch({
    type: 'VERIFY_CODE_SUBMIT_ACTION'
  })
}

export const verifyCodeCompleteAction = () => dispatch => {
  dispatch({
    type: 'VERIFY_CODE_COMPLETE_ACTION'
  })
}

export const verifyCodeErrorAction = (error) => dispatch => {
  dispatch({
    type: 'VERIFY_CODE_ERROR_ACTION',
    error: error
  })
}