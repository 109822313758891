const initialState = {
  error: '',
  firelogs: [],
  isFetching: false,
  hasFetched: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FIRELOG_REPORT_FETCH_ACTION':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'FIRELOG_REPORT_FETCH_SUCCESS_ACTION':
      return Object.assign({}, state, {
        isFetching: false,
        hasFetched: true,
        firelogs: action.items,
      })
    case 'FIRELOG_REPORT_FETCH_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
        hasFetched: true,
      })
    default:
      return state
  }
}