import React from 'react'
import { PasswordReset } from 'react-cognito'
import PasswordResetForm from './PasswordResetForm'

class PasswordResetPage extends React.Component {
  render = () => (
    <PasswordReset><PasswordResetForm /></PasswordReset>
  )
}

export default PasswordResetPage