const initialState = {
  error: '',
  fireTemp: '',
  length: '',
  width: '',
  height: '',
  total: '',
  pieces: '1',
  isSubmitting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FIRING_TEMP_CHANGE_ACTION':
      return Object.assign({}, state, {
        fireTemp: action.fireTemp
      })
    case 'FIRING_LENGTH_CHANGE_ACTION':
      return Object.assign({}, state, {
        length: action.length
      })
    case 'FIRING_WIDTH_CHANGE_ACTION':
      return Object.assign({}, state, {
        width: action.width
      })
    case 'FIRING_HEIGHT_CHANGE_ACTION':
      return Object.assign({}, state, {
        height: action.height
      })
    case 'FIRING_TOTAL_CHANGE_ACTION':
      return Object.assign({}, state, {
        total: action.total
      })
    case 'FIRING_PIECES_CHANGE_ACTION':
      return Object.assign({}, state, {
        pieces: action.pieces
      })
    case 'FIRING_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isSubmitting: true
      })
    case 'FIRING_SUBMIT_SUCCESS_ACTION':
      return Object.assign({}, state, {
        isSubmitting: false,
        fireTemp: '',
        length: '',
        width: '',
        height: '',
        total: '',
        pieces: '1',
        error: '',
      })
    case 'FIRING_SUBMIT_ERROR_ACTION':
      return Object.assign({}, state, {
        isSubmitting: false,
        error: action.error
      })
    case 'FIRING_SUBMIT_VALIDATION_ERROR_ACTION':
      return Object.assign({}, state, {
        isSubmitting: false,
        error: action.error
      })
    default:
      return state
  }
}