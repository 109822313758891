import { apiUrl } from '../lib/api'

export const firelogReportFetchAction = (token) => dispatch => {
  dispatch({
    type: 'FIRELOG_REPORT_FETCH_ACTION'
  })

  fetch(apiUrl() + '/firelog/user',
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {
      dispatch(firelogReportFetchSuccessAction(result))
    })
    .catch((error) => {
      dispatch(firelogReportFetchErrorAction(error))
    })
}

export const firelogReportFetchSuccessAction = (result) => dispatch => {
  dispatch({
    type: 'FIRELOG_REPORT_FETCH_SUCCESS_ACTION',
    items: result.Items,
  })
}

export const firelogReportFetchErrorAction = (error) => dispatch => {
  dispatch({
    type: 'FIRELOG_REPORT_FETCH_ERROR_ACTION',
    error: error
  })
}