import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap'

import {
  registerChangePasswordAction,
  registerChangePhoneAction,
  registerChangeFirstNameAction,
  registerChangeLastNameAction,
  registerUserAction,
  registerCompleteAction
} from '../../actions/registerActions'

import { phoneCheck } from '../../lib/phone'
import Error from '../common/Error'

class RegisterForm extends React.Component {
  static propTypes = {
    /* action creators from dispatch */
    registerChangePasswordAction: PropTypes.func.isRequired,
    registerChangePhoneAction: PropTypes.func.isRequired,
    registerChangeFirstNameAction: PropTypes.func.isRequired,
    registerChangeLastNameAction: PropTypes.func.isRequired,
    registerUserAction: PropTypes.func.isRequired,
    registerCompleteAction: PropTypes.func.isRequired,

    /* values from store */
    userPool: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,

    password: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    isRegistering: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.registerUserAction({
      userPool: this.props.userPool,
      config: this.props.config,
      password: this.props.password,
      phone_number: phoneCheck(this.props.phone),
      firstName: this.props.firstName,
      lastName: this.props.lastName
    })
  }

  render = () => (
    <Form onSubmit={this.onSubmit}>
      <Error message={this.props.error} />
      <FormGroup>
        <Label for="phone">Phone number (example: 5105554321)</Label>
        <Input
          type="number"
          placeholder="phone"
          disabled={this.props.isRegistering}
          onChange={this.props.registerChangePhoneAction}
          value={this.props.phone}
          required />
      </FormGroup>
      <FormGroup>
        <Label for="firstName">First Name</Label>
        <Input
          placeholder="First Name"
          disabled={this.props.isRegistering}
          onChange={this.props.registerChangeFirstNameAction}
          value={this.props.firstName}
          required />
      </FormGroup>
      <FormGroup>
        <Label for="lastName">Last Name</Label>
        <Input
          placeholder="Last Name"
          disabled={this.props.isRegistering}
          onChange={this.props.registerChangeLastNameAction}
          value={this.props.lastName}
          required />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password (at least 6 characters)</Label>
        <Input
          type="password"
          placeholder="password"
          disabled={this.props.isRegistering}
          onChange={this.props.registerChangePasswordAction}
          value={this.props.password}
          required />
      </FormGroup>
      <Button color="primary" type="submit" disabled={this.props.isRegistering}>Register</Button>
    </Form>
  )
}

const mapStateToProps = state => ({
  /* cognito */
  userPool: state.cognito.userPool,
  config: state.cognito.config,

  /* registerFormReducer */
  phone: state.registerFormReducer.phone,
  firstName: state.registerFormReducer.firstName,
  lastName: state.registerFormReducer.lastName,
  password: state.registerFormReducer.password,
  isRegistering: state.registerFormReducer.isRegistering,
  error: state.registerFormReducer.error,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    registerChangePasswordAction: registerChangePasswordAction,
    registerChangePhoneAction: registerChangePhoneAction,
    registerChangeFirstNameAction: registerChangeFirstNameAction,
    registerChangeLastNameAction: registerChangeLastNameAction,
    registerUserAction: registerUserAction,
    registerCompleteAction: registerCompleteAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
