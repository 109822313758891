import React from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'reactstrap'

class LogoutLink extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  }
  onClick = (event) => {
    event.preventDefault()
    this.props.onClick(event)
  }
  render = () => <NavLink onClick={this.onClick}>Log out</NavLink>
}

export default LogoutLink
