import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { CognitoState } from 'react-cognito'
import { Route, withRouter } from 'react-router' //react-router v4

import {
  Navbar,
  NavbarBrand,
} from 'reactstrap'

import SubNav from './SubNav'
import LoggedInNav from './LoggedInNav'
import LoggedOutNav from './LoggedOutNav'
import Dashboard from '../cognito/Dashboard'
import PasswordResetPage from '../cognito/PasswordResetPage'
import RegisterForm from '../cognito/RegisterForm'

import {
  logoutAction
} from '../../actions/logoutActions'

import './App.css'

class App extends React.Component {
  static propTypes = {
    logoutAction: PropTypes.func.isRequired,
    state: PropTypes.string,
    user: PropTypes.object
  }

  isLoggedIn = () => {
    switch (this.props.state) {
      case CognitoState.LOGGED_IN:
      case CognitoState.EMAIL_VERIFICATION_REQUIRED:
        return true
      default:
        return false
    }
  }

  isAdmin = () => {
    if (this.props.user.signInUserSession.idToken.payload.hasOwnProperty('cognito:groups')) {
      return this.props.user.signInUserSession.idToken.payload['cognito:groups'].indexOf('admin') > -1
    }
    return false
  }

  render = () => {
    return (
      <div>
        <header>
          <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/">Psfiring</NavbarBrand>
            {this.isLoggedIn() ? <LoggedInNav isAdmin={this.isAdmin()} /> : <LoggedOutNav />}
          </Navbar>
          {this.isLoggedIn() ? <SubNav /> : ''}
        </header>
        <main>
          <Route exact path="/" component={Dashboard} />
          <Route path="/admin" component={Dashboard} />
          <Route path="/user" component={Dashboard} />
          <Route exact path="/register" component={RegisterForm} />
          <Route exact path="/forgot_password" component={PasswordResetPage} />
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state.cognito.state,
  user: state.cognito.user,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    logoutAction: logoutAction
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
