import { apiUrl } from '../lib/api'
import download from 'downloadjs'

export const billingCycleFetchAction = (billingCycleId, token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_FETCH_ACTION'
  })
  fetch(apiUrl() + '/billing_cycle/' + billingCycleId,
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.json()
    })
    .then((result) => {
      dispatch(billingCycleFetchResultAction(result))
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(billingCycleListErrorAction(errorMessage.message))
      })
    })
}

export const userBillingCycleFetchAction = (billingCycleId, token) => dispatch => {
  dispatch({
    type: 'USER_BILLING_CYCLE_FETCH_ACTION'
  })
  fetch(apiUrl() + '/billing_cycle/' + billingCycleId,
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.json()
    })
    .then((result) => {
      dispatch(userBillingCycleFetchResultAction(result))
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(userBillingCycleFetchErrorAction(errorMessage.message))
      })
    })
}

export const billingCycleFetchResultAction = (item) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_FETCH_RESULT_ACTION',
    item: item
  })
}

export const userBillingCycleFetchResultAction = (billingCycle) => dispatch => {
  dispatch({
    type: 'USER_BILLING_CYCLE_FETCH_RESULT_ACTION',
    billingCycle: billingCycle
  })
}

export const billingCycleFetchErrorAction = (error) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_FETCH_ERROR_ACTION',
    error: error
  })
}

export const userBillingCycleFetchErrorAction = (error) => dispatch => {
  dispatch({
    type: 'USER_BILLING_CYCLE_FETCH_ERROR_ACTION',
    error: error
  })
}

export const billingCycleListAction = (token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_LIST_ACTION'
  })
  fetch(apiUrl() + '/billing_cycle',
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.json()
    })
    .then((results) => {
      dispatch(billingCycleListResultsAction(results))
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(billingCycleListErrorAction(errorMessage.message))
      })
    })
}

export const billingCycleListResultsAction = (results) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_LIST_RESULTS_ACTION',
    items: results.Items
  })
}

export const billingCycleListErrorAction = (error) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_LIST_ERROR_ACTION',
    error: error
  })
}

export const billingCycleFetchReportAction = (reportType, billingCycleId, name, token) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_FETCH_REPORT_ACTION'
  })

  var reportUrl = apiUrl() + '/billing_cycle_reports/' + reportType + '/' + billingCycleId
  var reportFilename = reportType + name + '.csv'

  fetch(reportUrl,
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.text()
    })
    .then((csv) => {
      download(csv, reportFilename, 'text/csv')
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(billingCycleFetchReportErrorAction(errorMessage.message))
      })
    })
}

export const billingCycleFetchReportErrorAction = (errorMessage) => {
  alert('Unhandled error: ' + errorMessage)
}