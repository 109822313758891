export const resetPasswordPhoneInputChangeAction = (event) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_PHONE_INPUT_CHANGE_ACTION',
    phone: event.target.value
  })
}

export const resetPasswordVerificationInputChangeAction = (event) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_VERIFICATION_INPUT_CHANGE_ACTION',
    code: event.target.value
  })
}

export const resetPasswordInputChangeAction = (event) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_INPUT_CHANGE_ACTION',
    password: event.target.value
  })
}

export const resetPasswordVerificationSubmitAction = (username, sendVerificationCode) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_VERIFICTION_SUBMIT_ACTION'
  })
  sendVerificationCode(username)
    .then(() => dispatch(resetPasswordVerificationSuccessAction()))
    .catch((err) => dispatch(resetPasswordVerificationErrorAction(err)))
}

export const resetPasswordVerificationSuccessAction = () => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_VERIFICATION_SUCCESS_ACTION',
    message: 'Verification code sent'
  })
}

export const resetPasswordVerificationErrorAction = (error) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_VERIFICATION_ERROR_ACTION',
    error: error
  })
}

export const resetPasswordSubmitAction = (passwordArgs, setPassword) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_SUBMIT_ACTION'
  })
  setPassword(
    passwordArgs.username,
    passwordArgs.code,
    passwordArgs.password
  )
    .then(() => dispatch(resetPasswordSuccessAction()))
    .catch((err) => dispatch(resetPasswordErrorAction(err)))
}

export const resetPasswordSuccessAction = () => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_SUCCESS_ACTION',
    message: 'Password reset'
  })
}

export const resetPasswordErrorAction = (error) => dispatch => {
  dispatch({
    type: 'RESET_PASSWORD_ERROR_ACTION',
    error: error
  })
}