import { cognitoIdentityServiceProvider } from '../lib/api'

export const billingCycleImportUserModalToggleAction = () => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_MODAL_TOGGLE_ACTION'
  })
}

export const billingCycleImportUserModalSaveAction = (selectedUsers) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_MODAL_SAVE_ACTION',
    selectedUsers: selectedUsers,
  })
}

export const billingCycleImportUserSelect = (username, isSelected) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_SELECT',
    username: username,
    isSelected: isSelected,
  })
}

export const billingCycleImportUserQueryAction = (billingCycleId, Cognito) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_QUERY_ACTION'
  })

  var params = {
    UserPoolId: Cognito.user.pool.userPoolId,
    Filter: 'name = "' + billingCycleId + '"'
  }

  let cognitoApi = cognitoIdentityServiceProvider(Cognito)
  cognitoApi.listUsers(params, (err, result) => {
    if (err) {
      dispatch(billingCycleImportUserQueryErrorAction(err))
    }

    if (result) {
      dispatch(billingCycleImportUserQuerySuccessAction(result))
    }
  })
}

export const billingCycleImportUserQuerySuccessAction = (result) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_QUERY_SUCCESS_ACTION',
    users: result['Users'],
    selectedUsers: result['Users'].map(user => { return user.Username }),
  })
}

export const billingCycleImportUserQueryErrorAction = (error) => dispatch => {
  dispatch({
    type: 'BILLING_CYCLE_IMPORT_USER_QUERY_ERROR_ACTION',
    error: error,
  })
}