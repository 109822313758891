import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Form,
  FormGroup,
  Input,
  Button
} from 'reactstrap'

import {
  billingCycleListAction
} from '../../actions/billingCycleActions'

import {
  userAdminSearchAttributeChangeAction,
  userAdminSearchTermChangeAction,
  userAdminSearchSubmitAction,
} from '../../actions/userAdminActions'

import {
  phoneCheck
} from '../../lib/phone'

import UserListItem from './UserListItem'
import Error from '../common/Error'
import UserSetBillingCycleModal from './UserSetBillingCycleModal'

class UserAdmin extends React.Component {
  static propTypes = {
    // billinCycleActions
    billingCycleListAction: PropTypes.func.isRequired,

    // userAdminsActions
    userAdminSearchAttributeChangeAction: PropTypes.func.isRequired,
    userAdminSearchTermChangeAction: PropTypes.func.isRequired,
    userAdminSearchSubmitAction: PropTypes.func.isRequired,

    // cognito
    cognito: PropTypes.object.isRequired,

    // billingCycleReducer
    billingCycleError: PropTypes.string.isRequired,
    hasMore: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,

    // userAdminsReducer
    isSubmitting: PropTypes.bool.isRequired,
    searchAttribute: PropTypes.string.isRequired,
    searchTerm: PropTypes.string.isRequired,
    userAdminError: PropTypes.string.isRequired,
    users: PropTypes.array,
  }

  componentDidMount() {
    if (this.props.items.length === 0) {
      let token = this.props.cognito.user.signInUserSession.idToken.jwtToken
      this.props.billingCycleListAction(token)
    }
  }

  onSubmit = (event) => {
    event.preventDefault()
    var searchTerm = this.props.searchTerm
    if (this.props.searchAttribute === 'phone') {
      searchTerm = phoneCheck(this.props.searchTerm)
    }
    this.props.userAdminSearchSubmitAction(this.props.cognito, this.props.searchAttribute, searchTerm)
  }

  userAdminRaiseModalAction

  renderUsers = (users) => {
    if (!users) {
      return
    }

    return (
      <div>
        <div className="divTable greyGridTable">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div className="divTableHead">Name</div>
              <div className="divTableHead">Phone Number</div>
              <div className="divTableHead">User Status</div>
              <div className="divTableHead">Billing Cycle</div>
              <div className="divTableHead">Report</div>
            </div>
          </div>
          {users.map((item, index) => (
            <UserListItem user={item} key={index} />
          ))}
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div>
        <h1>User Admin</h1>
        <Error error={this.props.billingCycleError} />
        <Error error={this.props.userAdminError} />
        <Form inline onSubmit={this.onSubmit.bind(this)}>
          <FormGroup>
            <Input
              type="select"
              id="searchAttribute"
              name="searchAttribute"
              value={this.props.searchAttribute}
              onChange={this.props.userAdminSearchAttributeChangeAction}>
              <option value="given_name">First Name</option>
              <option value="family_name">Last Name</option>
              <option value="phone_number">Phone Number</option>
            </Input>
            <Input
              id="searchTerm"
              name="searchTerm"
              placeholder="Search"
              value={this.props.searchTerm}
              disabled={this.props.isSubmitting}
              onChange={this.props.userAdminSearchTermChangeAction} />
          </FormGroup>
          <Button type="submit" color="primary" disabled={this.props.isSubmitting}>Search</Button>
        </Form>
        {this.renderUsers(this.props.users)}
        <UserSetBillingCycleModal />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  billingCycleError: state.billingCycleReducer.error,
  cognito: state.cognito,
  hasMore: state.billingCycleReducer.hasMore,
  items: state.billingCycleReducer.items,
  isSubmitting: state.userAdminReducer.isSubmitting,
  searchAttribute: state.userAdminReducer.searchAttribute,
  searchTerm: state.userAdminReducer.searchTerm,
  userAdminError: state.userAdminReducer.error,
  users: state.userAdminReducer.users,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    billingCycleListAction: billingCycleListAction,
    userAdminSearchAttributeChangeAction: userAdminSearchAttributeChangeAction,
    userAdminSearchTermChangeAction: userAdminSearchTermChangeAction,
    userAdminSearchSubmitAction: userAdminSearchSubmitAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin)