import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import {
  userAdminSetBillingCycleAction,
  userAdminCloseModalAction
} from '../../actions/userAdminActions'

import './UserSetBillingCycleModal.css'

class UserSetBillingCycleModal extends React.Component {
  static propTypes = {
    userAdminSetBillingCycleAction: PropTypes.func.isRequired,
    userAdminCloseModalAction: PropTypes.func.isRequired,

    cognito: PropTypes.object.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    items: PropTypes.array,
    user: PropTypes.object,
  }

  toggle = () => {
    this.props.userAdminCloseModalAction()
  }

  selectBillingCycle = (event) => {
    this.props.userAdminSetBillingCycleAction(this.props.cognito, this.props.user.Username, event.target.value)
  }

  renderBillingCycleList = () => {
    if (!this.props.items || !this.props.user) {
      return
    }

    var userBillingCycle = ''
    var userBillingCycleAttribute = this.props.user.Attributes.find(obj => obj.Name === 'name')
    if (userBillingCycleAttribute) {
      userBillingCycle = userBillingCycleAttribute.Value
    }

    return this.props.items.map((item, index) => (
      <div key={index} className="userBillingCycle">
        <Input
          type="radio"
          value={item.billingCycleId}
          checked={item.billingCycleId === userBillingCycle}
          onChange={this.selectBillingCycle} />
        {item.name}
      </div>
    ))
  }

  render = () => {
    return (
      <Modal isOpen={this.props.isModalOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Change Billing Cycle</ModalHeader>
        <ModalBody>{this.renderBillingCycleList()}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}


const mapStateToProps = state => ({
  cognito: state.cognito,
  items: state.billingCycleReducer.items,
  user: state.userAdminReducer.modalUser,
  isModalOpen: state.userAdminReducer.isModalOpen,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userAdminSetBillingCycleAction: userAdminSetBillingCycleAction,
    userAdminCloseModalAction: userAdminCloseModalAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSetBillingCycleModal)