import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Button } from 'reactstrap'
import { FaPencilAlt } from 'react-icons/fa'

import {
  userAdminCreateReportAction,
  userAdminRaiseModalAction,
} from '../../actions/userAdminActions'

class UserAdmin extends React.Component {
  static propTypes = {
    userAdminCreateReportAction: PropTypes.func.isRequired,
    userAdminRaiseModalAction: PropTypes.func.isRequired,

    items: PropTypes.array.isRequired,
    cognitoUser: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }

  onClickEditUserBillingCycle = () => {
    this.props.userAdminRaiseModalAction(this.props.user)
  }

  onClickCreateUserReport = () => {
    let token = this.props.cognitoUser.signInUserSession.idToken.jwtToken
    this.props.userAdminCreateReportAction(this.props.user.Username, token)
  }

  render = () => {
    var userAttributes = this.props.user.Attributes.reduce((map, obj) => {
      map[obj.Name] = obj.Value
      return map
    })

    var billingCycleName = ''
    var billingCycle = this.props.items.find(obj => obj.billingCycleId === userAttributes.name)
    if (billingCycle) {
      billingCycleName = billingCycle.name
    }

    return (
      <div className="divTableRow">
        <div className="divTableCell">{userAttributes.given_name} {userAttributes.family_name}</div>
        <div className="divTableCell">{userAttributes.phone_number}</div>
        <div className="divTableCell">{this.props.user.UserStatus}</div>
        <div className="divTableCell">
          {billingCycleName}
          <Button onClick={this.onClickEditUserBillingCycle}><FaPencilAlt /></Button>
        </div>
        <div className="divTableCell"><Button color="primary" onClick={this.onClickCreateUserReport}>Report</Button></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cognitoUser: state.cognito.user,
  items: state.billingCycleReducer.items
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userAdminCreateReportAction: userAdminCreateReportAction,
    userAdminRaiseModalAction: userAdminRaiseModalAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin)