const initialState = {
  code: '',
  error: '',
  isVerifying: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFY_CODE_CHANGE_ACTION':
      return Object.assign({}, state, {
        code: action.code
      })
    case 'VERIFY_CODE_SUBMIT_ACTION':
      return Object.assign({}, state, {
        isVerifying: true
      })
    case 'VERIFY_CODE_COMPLETE_ACTION':
      return Object.assign({}, state, {
        code: '',
        error: '',
        isVerifying: false
      })
    case 'VERIFY_CODE_ERROR_ACTION':
      return Object.assign({}, state, {
        error: action.error,
        isVerifying: false
      })
    default:
      return state
  }
}