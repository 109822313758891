import React from 'react'
import { Link } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'

const LoggedOutNav = () => (
  <Nav className="ml-auto" navbar>
    <NavItem><NavLink tag={Link} to="/">Home</NavLink></NavItem>
    <NavItem><NavLink tag={Link} to="/register">Register</NavLink></NavItem>
    <NavItem><NavLink tag={Link} to="/forgot_password">Forgot Password</NavLink></NavItem>
  </Nav>
)

export default LoggedOutNav