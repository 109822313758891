import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

import {
  billingCycleImportUserModalToggleAction,
} from '../../actions/billingCycleImportUserListModalActions'

import {
  billingCycleFormGetById,
  billingCycleFormSetProps,
  billingCycleNameChangeAction,
  billingCycleTypeChangeAction,
  billingCycleStartDateChangeAction,
  billingCycleEndDateChangeAction,
  billingCycleRateLowChangeAction,
  billingCycleRateMidChangeAction,
  billingCycleRateHighChangeAction,
  billingCycleRateHighOGChangeAction,
  billingCycleIncludedVolumeChangeAction,
  
  billingCycleSubmitAction,
  billingCycleSubmitUpdateAction,
} from '../../actions/billingCycleFormActions'

import Error from '../common/Error'
import BillingCycleImportUserListModal from './BillingCycleImportUserListModal';

class BillingCycleForm extends React.Component {
  static propTypes = {
    // billingCycleFormActions
    billingCycleFormGetById: PropTypes.func.isRequired,
    billingCycleFormSetProps: PropTypes.func.isRequired,
    billingCycleNameChangeAction: PropTypes.func.isRequired,
    billingCycleTypeChangeAction: PropTypes.func.isRequired,
    billingCycleStartDateChangeAction: PropTypes.func.isRequired,
    billingCycleEndDateChangeAction: PropTypes.func.isRequired,
    billingCycleRateLowChangeAction: PropTypes.func.isRequired,
    billingCycleRateMidChangeAction: PropTypes.func.isRequired,
    billingCycleRateHighChangeAction: PropTypes.func.isRequired,
    billingCycleRateHighOGChangeAction: PropTypes.func.isRequired,
    billingCycleIncludedVolumeChangeAction: PropTypes.func.isRequired,
    billingCycleImportUserModalToggleAction: PropTypes.func.isRequired,
    billingCycleSubmitAction: PropTypes.func.isRequired,
    billingCycleSubmitUpdateAction: PropTypes.func.isRequired,

    // cognito
    user: PropTypes.object.isRequired,

    // route props
    match: PropTypes.object,

    // billingCycleReducer
    items: PropTypes.array.isRequired,

    // billingCycleFormReducer
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    rateLow: PropTypes.string.isRequired,
    rateMid: PropTypes.string.isRequired,
    rateHigh: PropTypes.string.isRequired,
    rateHighOG: PropTypes.string.isRequired,
    includedVolume: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    userImportList: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
  }

  componentDidMount() {
    if (!this.isNew()) {
      let id = this.props.match.params.id
      let item = this.props.items.find(i => i.billingCycleId === id)
      if (!item) {
        let token = this.props.user.signInUserSession.idToken.jwtToken
        this.props.billingCycleFormGetById(id, token)
      } else {
        this.props.billingCycleFormSetProps(item)
      }
    }
  }

  isNew = () => {
    return this.props.match.params.id === 'new'
  }

  onClickImportUsers = () => {
    this.props.billingCycleImportUserModalToggleAction()
  }

  onSubmit = (event) => {
    event.preventDefault()
    let startTime = new Date(this.props.startDate)
    let endTime = new Date(this.props.endDate)
    let data = {
      name: this.props.name,
      type: this.props.type,
      startDate: startTime.getTime() / 1000,
      endDate: endTime.getTime() / 1000,
      rateLow: this.props.rateLow,
      rateMid: this.props.rateMid,
      rateHigh: this.props.rateHigh,
      rateHighOG: this.props.rateHighOG,
      includedVolume: this.props.includedVolume,
      userImportList: this.props.userImportList,
    }

    let token = this.props.user.signInUserSession.idToken.jwtToken
    let billingCycleId = this.props.match.params.id
    if (billingCycleId === 'new') {
      this.props.billingCycleSubmitAction(data, token)
    } else {
      this.props.billingCycleSubmitUpdateAction(billingCycleId, data, token)
    }
  }

  renderUserImportList = () => {
    return this.props.userImportList.map((user, index) => {
      return <div key={index}>{user['given_name']} {user['family_name']} {user['phone_number']}</div>
    })
  }

  render = () => {
    return (
      <Form onSubmit={this.onSubmit}>
        <BillingCycleImportUserListModal />
        <h1>Billing Cycle Form</h1>
        <Error error={this.props.error} />
        <FormGroup tag="fieldset">
          <legend>User import</legend>
          {this.renderUserImportList()}
          <Button onClick={this.onClickImportUsers}>Import users</Button>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Billing Cycle Type</legend>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="billingCycleType"
                value="Member"
                disabled={this.props.isSubmitting}
                onChange={this.props.billingCycleTypeChangeAction}
                checked={this.props.type === 'Member'} /> Member
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="billingCycleType"
                value="Student"
                disabled={this.props.isSubmitting}
                onChange={this.props.billingCycleTypeChangeAction}
                checked={this.props.type === 'Student'} /> Student
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="billingCycleType"
                value="Member-Shareholder"
                disabled={this.props.isSubmitting}
                onChange={this.props.billingCycleTypeChangeAction}               
                checked={this.props.type === 'Member-Shareholder'} /> Member-Shareholder
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label for="name">Billing Cycle Name</Label>
          <Input
            id="name"
            placeholder="Billing Cycle Name"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleNameChangeAction}
            value={this.props.name}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="startDate">Start Date</Label>
          <Input
            type="date"
            id="date"
            placeholder="Start Date"
            value={this.props.startDate}
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleStartDateChangeAction}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">End Date</Label>
          <Input
            type="date"
            id="date"
            placeholder="End Date"
            value={this.props.endDate}
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleEndDateChangeAction} />
        </FormGroup>
        <FormGroup>
          <Label for="rateLow">Rate Low</Label>
          <Input
            id="rateLow"
            placeholder="Rate Low"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleRateLowChangeAction}
            value={this.props.rateLow}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="rateMid">Rate Mid</Label>
          <Input
            id="rateMid"
            placeholder="Rate Mid"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleRateMidChangeAction}
            value={this.props.rateMid}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="rateHigh">Rate High</Label>
          <Input
            id="rateHigh"
            placeholder="Rate High"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleRateHighChangeAction}
            value={this.props.rateHigh}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="rateHighOG">Rate High-Own Glaze</Label>
          <Input
            id="rateHighOG"
            placeholder="Rate High-Own Glaze"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleRateHighOGChangeAction}
            value={this.props.rateHighOG}
            required />
        </FormGroup>
        <FormGroup>
          <Label for="includedVolume">Included Volume</Label>
          <Input
            id="includedVolume"
            placeholder="Included Volume"
            disabled={this.props.isSubmitting}
            onChange={this.props.billingCycleIncludedVolumeChangeAction}
            value={this.props.includedVolume}
            required />
        </FormGroup>
        <Button color="primary" type="submit" disabled={this.props.isSubmitting}>
          {this.isNew() ? 'Create' : 'Update'}
        </Button>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user,
  items: state.billingCycleReducer.items,
  name: state.billingCycleFormReducer.name,
  type: state.billingCycleFormReducer.type,
  startDate: state.billingCycleFormReducer.startDate,
  endDate: state.billingCycleFormReducer.endDate,
  rateLow: state.billingCycleFormReducer.rateLow,
  rateMid: state.billingCycleFormReducer.rateMid,
  rateHigh: state.billingCycleFormReducer.rateHigh,
  rateHighOG: state.billingCycleFormReducer.rateHighOG,
  includedVolume: state.billingCycleFormReducer.includedVolume,
  isSubmitting: state.billingCycleFormReducer.isSubmitting,
  userImportList: state.billingCycleFormReducer.userImportList,
  error: state.billingCycleFormReducer.error,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    billingCycleFormGetById: billingCycleFormGetById,
    billingCycleFormSetProps: billingCycleFormSetProps,
    billingCycleNameChangeAction: billingCycleNameChangeAction,
    billingCycleTypeChangeAction: billingCycleTypeChangeAction,
    billingCycleStartDateChangeAction: billingCycleStartDateChangeAction,
    billingCycleEndDateChangeAction: billingCycleEndDateChangeAction,
    billingCycleRateLowChangeAction: billingCycleRateLowChangeAction,
    billingCycleRateMidChangeAction: billingCycleRateMidChangeAction,
    billingCycleRateHighChangeAction: billingCycleRateHighChangeAction,
    billingCycleRateHighOGChangeAction: billingCycleRateHighOGChangeAction,
    billingCycleIncludedVolumeChangeAction: billingCycleIncludedVolumeChangeAction,
    billingCycleImportUserModalToggleAction: billingCycleImportUserModalToggleAction,
    billingCycleSubmitAction: billingCycleSubmitAction,
    billingCycleSubmitUpdateAction: billingCycleSubmitUpdateAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycleForm)