import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'

export const apiUrl = () => {
  return process.env.REACT_APP_API_URL
}

export const cognitoIdentityServiceProvider = (Cognito) => {
  window.AWS.config.update({
    credentials: Cognito.creds
  })

  return new CognitoIdentityServiceProvider()
}