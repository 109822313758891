import { apiUrl } from '../lib/api'
import download from 'downloadjs'
import { cognitoIdentityServiceProvider } from '../lib/api'

export const userAdminCreateReportAction = (username, token) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_CREATE_REPORT_ACTION'
  })

  var reportUrl = apiUrl() + '/firelog_reports/user/' + username
  var reportFilename = 'user' + username + '.csv'

  fetch(reportUrl,
    {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
    .then((response) => {
      if(response.status !== 200){
        throw response
      }
      return response.text()
    })
    .then((csv) => {
      download(csv, reportFilename, 'text/csv')
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        dispatch(userAdminCreateReportErrorAction(errorMessage.message))
      })
    })
}

export const userAdminCreateReportErrorAction = (errorMessage) => {
  alert('Unhandled error: ' + errorMessage)
}

export const userAdminSearchAttributeChangeAction = (event) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SEARCH_ATTRIBUTE_CHANGE_ACTION',
    searchAttribute: event.target.value
  })
}

export const userAdminSearchTermChangeAction = (event) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SEARCH_TERM_CHANGE_ACTION',
    searchTerm: event.target.value
  })
}

export const userAdminSearchSubmitAction = (Cognito, searchAttribute, searchTerm) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SEARCH_SUBMIT_ACTION'
  })

  var params = {
    UserPoolId: Cognito.user.pool.userPoolId,
    Filter: searchAttribute + ' ^= "' + searchTerm + '"'
  }

  let cognitoApi = cognitoIdentityServiceProvider(Cognito)
  cognitoApi.listUsers(params, (err, result) => {
    if (err) {
      dispatch(userAdminSearchErrorAction(err))
    }

    if (result) {
      dispatch(userAdminSearchSuccessAction(result))
    }
  })
}

export const userAdminSearchSuccessAction = (result) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SEARCH_SUCCESS_ACTION',
    result: result
  })
}

export const userAdminSearchErrorAction = (error) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SEARCH_ERROR_ACTION',
    error: error
  })
}

export const userAdminSetBillingCycleAction = (Cognito, username, billingCycleId) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SET_BILLING_CYCLE_ACTION',
  })

  var params = {
    'UserAttributes': [
      {
        'Name': 'name',
        'Value': billingCycleId
      }
    ],
    'Username': username,
    'UserPoolId': Cognito.user.pool.userPoolId,
  }

  let cognitoApi = cognitoIdentityServiceProvider(Cognito)
  cognitoApi.adminUpdateUserAttributes(params, (err, result) => {
    if (err) {
      dispatch(userAdminSetBillingCycleErrorAction(err))
    }

    if (result) {
      dispatch(userAdminSetBillingCycleSuccessAction(result))
    }
  })
}

export const userAdminSetBillingCycleSuccessAction = (result) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SET_BILLING_CYCLE_SUCCESS_ACTION',
    result: result
  })
}

export const userAdminSetBillingCycleErrorAction = (error) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_SET_BILLING_CYCLE_ERROR_ACTION',
    error: error
  })
}

export const userAdminRaiseModalAction = (user) => dispatch => {
  dispatch({
    type: 'USER_ADMIN_RAISE_MODAL_ACTION',
    user: user
  })
}

export const userAdminCloseModalAction = () => dispatch => {
  dispatch({
    type: 'USER_ADMIN_CLOSE_MODAL_ACTION'
  })
}