import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  Button,
  ButtonGroup,
} from 'reactstrap'

import {
  billingCycleFetchReportAction
} from '../../actions/billingCycleActions'


class BillingCycleListItem extends React.Component {
  static propTypes = {
    billingCycleFetchReportAction: PropTypes.func.isRequired,

    // cognito
    user: PropTypes.object.isRequired,

    // inline props from infinite scroll component
    item: PropTypes.object.isRequired,
  }

  downloadReportClick = (reportType) => {
    let token = this.props.user.signInUserSession.idToken.jwtToken
    this.props.billingCycleFetchReportAction(reportType, this.props.item.billingCycleId, this.props.item.name, token)
  }

  render = () => {
    let item = this.props.item
    let url = '/admin/billing_cycles/' + item.billingCycleId
    let startDate = moment.unix(item.startDate).utc().format('MMMM Do YYYY')
    let endDate = moment.unix(item.endDate).utc().format('MMMM Do YYYY')

    return (
      <div className="divTableRow">
        <div className="divTableCell"><Link to={url}>{item.name}</Link></div>
        <div className="divTableCell">{item.type}</div>
        <div className="divTableCell">{startDate}</div>
        <div className="divTableCell">{endDate}</div>
        <div className="divTableCell">
          <ButtonGroup size="sm">
            <Button color="primary" onClick={this.downloadReportClick.bind(this, 'complete')}>complete report</Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    billingCycleFetchReportAction: billingCycleFetchReportAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycleListItem)