
import React from 'react'
import ReactDOM from 'react-dom'
import { setupCognito } from 'react-cognito'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './components/app/App'
import registerServiceWorker from './registerServiceWorker'
import configureStore from './store'
import { cognitoConfig } from './lib/cognito'

const history = createBrowserHistory()
let store = configureStore(history)
setupCognito(store, cognitoConfig())

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider >,
  document.getElementById('root')
)
registerServiceWorker()
