import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap'

import {
  loginChangeUsernameAction,
  loginChangePasswordAction,
  loginSubmitAction,
  loginClearCache
} from '../../actions/loginActions'

import { phoneCheck } from '../../lib/phone'
import Error from '../common/Error'

class LoginForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    loginChangeUsernameAction: PropTypes.func.isRequired,
    loginChangePasswordAction: PropTypes.func.isRequired,
    loginSubmitAction: PropTypes.func.isRequired,
    loginClearCache: PropTypes.func.isRequired,

    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(phoneCheck(this.props.username), this.props.password)
    this.props.loginSubmitAction()
  }

  componentWillUnmount = () => {
    this.props.loginClearCache()
  }

  render = () => (
    <Form onSubmit={this.onSubmit}>
      <Error error={this.props.error} />
      <FormGroup>
        <Label for="phone">Phone Number</Label>
        <Input
          type="number"
          placeholder="Phone Number"
          value={this.props.username}
          disabled={this.props.isLoggingIn}
          onChange={this.props.loginChangeUsernameAction}
          required />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          type="password"
          placeholder="Password"
          value={this.props.password}
          disabled={this.props.isLoggingIn}
          onChange={this.props.loginChangePasswordAction}
          required />
      </FormGroup>
      <Button color="primary" type="submit" disabled={this.props.isLoggingIn}>Sign in</Button>
    </Form>
  )
}

const mapStateToProps = state => ({
  username: state.loginFormReducer.username,
  password: state.loginFormReducer.password,
  isLoggingIn: state.loginFormReducer.isLoggingIn,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginChangeUsernameAction: loginChangeUsernameAction,
    loginChangePasswordAction: loginChangePasswordAction,
    loginSubmitAction: loginSubmitAction,
    loginClearCache: loginClearCache
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)